import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Quicklinks from '../../../components/Quicklinks';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const FeelingSound = () => (
  <Layout
    title="Feeling Sound - Science at Home"
    className="science-starters has-video"
  >
    {/* Intro Title and Video */}
    <Section className="science-starters__video">
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-home">Science at Home</Link>
          </li>
          <li className="breadcrumb active">Feeling Sound</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">Feeling Sound</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link
                to="../diffusion-with-miss-america-2020"
                className="previous"
              >
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="24558f88-ae0c-4d5f-a771-b030366eb38e"
                className="de-vid"
              ></Video>
              <Link to="../inflation-station" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pt-0 pb-6">
      <Container>
        <Row className="mb-4">
          <Column size={8} offset={1}>
            <h2 className="science-at-home__intro">
              Believe it or not, you can feel sound! Join Gitanjali Rao, former
              Discovery Education 3M Young Scientist Challenge winner of 2017,
              as she teaches us about the frequency of sound and how we perceive
              pitch.
            </h2>
          </Column>
        </Row>
        <Row>
          <Column size={8} offset={1} className="science-home__content">
            {/* Quick Links */}
            <Quicklinks
              className="pl-0"
              title="Quicklinks:"
              smoothScroll
              links={[
                {
                  target: '#background',
                  label: 'Background',
                },
                {
                  target: '#key-concepts',
                  label: 'Key Concepts',
                },
                {
                  target: '#materials',
                  label: 'Materials',
                },
                {
                  target: '#safety',
                  label: 'Safety',
                },
                {
                  target: '#standards',
                  label: 'Standards',
                },
              ]}
            />
            {/* Background */}
            <h3 id="background">Background</h3>
            <p>
              While the history of "sound science" is broad and many famous
              scientists have made important contributions, it is Leonardi
              DaVinci who is generally credited with discovering that sound
              actually travels in waves. However, in the 17th century, Galileo
              made great advancements into how we perceive sound and how sound
              travels. He found out about frequency of sound, and how that
              determines pitch. If the vibrations of a sound happen far apart,
              the sound will have a low frequency and sound deep. If the
              vibrations are close together, that will create a high-pitched
              sound. The way that humans perceive these vibrations is something
              we will explore in this experiment.
            </p>
            {/* Key Concepts */}
            <h3 id="key-concepts">Key Concepts</h3>
            <ul>
              <li>Waves</li>
              <li>Energy</li>
              <li>Sound</li>
            </ul>
            {/* Materials */}
            <h3 id="materials">Materials</h3>
            <ul>
              <li>An adult helper</li>
              <li>Balloon</li>
              <li>Speaker</li>
            </ul>
            <h3>Preparation</h3>
            <ul>
              <li>
                Blow up the balloon so it is almost all the way full, and tie
                the end.
              </li>
              <li>
                Make a prediction: What will the balloon feel like when a loud
                noise happens near it?
              </li>
              <li>Hold the balloon in both hands, one hand on either side</li>
              <li>
                Hold the balloon in front of your face and say "ahhhhh" at a
                medium volume
              </li>
              <li>Observe what happens to the balloon. What do you feel?</li>
              <li>
                Try raising and lowering your voice in pitch (high notes and low
                notes) and volume (loud voice and quiet voice). What changes do
                you observe with how the balloon feels in your hands?
              </li>
              <li>
                Try playing a song or other audio using a speaker, and hold the
                balloon in front of the speaker. What do you notice about the
                way the balloon feels?
              </li>
              <li>
                Make sure to clean up when you are done. Pop the balloon and
                throw it away. Turn off your speaker system to conserve
                electricity.
              </li>
            </ul>
            <h3>Observation and Results</h3>
            <p>
              You should feel the balloon vibrate when you make noise. The
              intensity of the vibrations should change based on how loud the
              noise is, and the pitch the noise is. This happens because sound
              travels in waves. Sound waves are what are called longitudinal
              waves, which means it travels in the same direction as the
              disturbance that caused it. Sound is caused by something
              vibrating, which compresses and stretches the air around it, which
              vibrates your ear drum, and your brain interprets that vibration
              as sound. The interesting thing about this experiment is that you
              can feel those vibrations with your hands instead of just hearing
              them as sound. Think about where the vibrations from step three
              came from. Are those similar or different to the vibrations from
              step six?
            </p>
            <p>
              Think about other situations where you have felt sound. Have you
              ever been able to tell a car was playing music before it even
              drove down your street? How do you think sound travels through
              different materials, like water or metal? Is there a way you could
              test your guesses? Are balloons the best object to feel sound
              through? See if you can find a different object that acts like a
              balloon when you make noise near it.
            </p>
            {/* Safety */}
            <h3 id="safety">Safety First and Adult Supervision</h3>
            <ul>
              <li>Follow the experiment’s instructions carefully.</li>
              <li>A responsible adult should assist with each experiment.</li>
              <li>
                While science experiments at home are exciting ways to learn
                about science hands-on, please note that some may require
                participants to take extra safety precautions and/or make a
                mess.
              </li>
              <li>
                Adults should handle or assist with potentially harmful
                materials or sharp objects.
              </li>
              <li>
                Adult should review each experiment and determine what the
                appropriate age is for the student’s participation in each
                activity before conducting any experiment.
              </li>
            </ul>
            {/* Standards */}
            <h3 id="standards">
              Next Generation Science Standard (NGSS) Supported - Disciplinary
              Core Ideas
            </h3>
            <p>
              This experiment was selected for Science at Home because it
              teaches NGSS Disciplinary Core Ideas, which have broad importance
              within or across multiple science or engineering disciplines.
            </p>
            <p>
              Learn more about how this experiment is based in NGSS Disciplinary
              Core Ideas.
            </p>
            <p className="bold">
              Engineering Design (ETS)1: Engineering Design
            </p>
            <div className="accordion">
              <div className="accordion__group">
                <div className="accordion__item">
                  <input type="checkbox" id="chck1"></input>
                  <label className="label" for="chck1">
                    PS3.A: Definitions of Energy
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-2, 3.</em> Energy can be moved from place to
                        place by moving objects or through sound, light, or
                        electric currents.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS3-2, 3.</em> At the macroscopic scale, energy
                        manifests itself in multiple ways, such as in motion,
                        sound, light, and thermal energy.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck2"></input>
                  <label className="label" for="chck2">
                    PS3.B: Conservation of Energy and Energy Transfer
                  </label>
                  <div className="content">
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS3-2, 3.</em> Energy is present whenever there
                        are moving objects, sound, light, or heat. When objects
                        collide, energy can be transferred from one object to
                        another, thereby changing their motion. In such
                        collisions, some energy is typically also transferred to
                        the surrounding air; as a result, the air gets heated
                        and sound is produced.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck3"></input>
                  <label className="label" for="chck3">
                    PS4.A: Wave Properties
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>1-PS4-1.</em> Sound can make matter vibrate, and
                        vibrating matter can make sound.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS4-1.</em> Waves of the same type can differ in
                        amplitude (height of the wave) and wavelength (spacing
                        between wave peaks).
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS4-1.</em> A simple wave has a repeating pattern
                        with a specific wavelength, frequency, and amplitude.
                      </li>
                      <li>
                        <em>MS-PS4-2.</em> A sound wave needs a medium through
                        which it is transmitted.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS4-1.</em> The wavelength and frequency of a
                        wave are related to one another by the speed of travel
                        of the wave, which depends on the type of wave and the
                        medium through which it is passing.
                      </li>
                      <li>
                        <em>HS-PS4-2.</em> Information can be digitized.
                      </li>
                      <li>
                        <em>HS-PS4-5.</em> In digital form, information can be
                        stored reliably and sent over long distances as a series
                        of wave pulses.
                      </li>
                      <li>
                        <em>HS-PS4-3.</em> Waves can add or cancel one another
                        as they cross, depending on their relative phase, but
                        they emerge unaffected by each other.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="accordion__item">
                  <input type="checkbox" id="chck4"></input>
                  <label className="label" for="chck4">
                    PS4.C: Information Technologies and Instrumentation
                  </label>
                  <div className="content">
                    <h4>Grades K-2</h4>
                    <ul>
                      <li>
                        <em>1-PS4-4.</em> People use a variety of devices to
                        communicate over long distances.
                      </li>
                    </ul>
                    <h4>Grades 3-5</h4>
                    <ul>
                      <li>
                        <em>4-PS4-3.</em> Digitized information can be
                        transmitted over long distances without significant
                        degradation.
                      </li>
                    </ul>
                    <h4>Grades 6-8</h4>
                    <ul>
                      <li>
                        <em>MS-PS4-3.</em> Digitized signals (sent as wave
                        pulses) are a more reliable way to encode and transmit
                        information.
                      </li>
                    </ul>
                    <h4>Grades 9-12</h4>
                    <ul>
                      <li>
                        <em>HS-PS4-5.</em> Multiple technologies base on the
                        understanding of waves and their interaction s with
                        matter are part of everyday experiences in the modern
                        world and in scientific research. They are essential
                        tools for producing, transmitting, and capturing signals
                        and for storing and interpreting the information
                        contained in them.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Column>
          {/* PI team still trying to decide if we're going 
              to use these callouts prior to site launch
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="career-right-image">
                <Image filename="FeelingSound-Profile-Square.jpg" />
              </div>
              <div className="global__profile-content">
                <h3>Gitanjali Rao</h3>
                <h4>
                  2017 Discovery Education 3M Young Scientist Challenge winner
                </h4>
              </div>
            </div>
          </Column> */}
        </Row>
      </Container>
    </Section>
    {/* Explore Additional Science at Home Videos and Activities */}
    <Section className="science-at-home__cards pb-6">
      <Container>
        <Row>
          <Column fullWidth>
            <h2>Explore Additional Science at Home Videos and Activities</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="DiffusionWithMiss-Thumbnail.png" />
              <div className="card__content">
                <h3>Diffusion with Miss America 2020</h3>
                <h4>
                  Ever wonder why things mix (or don’t mix) differently in
                  different temperatures of water?
                </h4>
                <p>
                  Join Camille Schrier, a scientist who was crowned Miss America
                  2020, as she explains diffusion and how substances move though
                  water.
                </p>
              </div>
              <Link
                to="../diffusion-with-miss-america-2020"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="InflationStation-Thumbnail.png" />
              <div className="card__content">
                <h3>Inflation Station</h3>
                <p>
                  Follow along with 3M’s Chief Science Advocate, Jayshree Seth,
                  as she teaches students how chemistry can help put some air
                  where it’s most needed!
                </p>
              </div>
              <Link
                to="../inflation-station"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
          <Column size={4}>
            <div className="card box-shadow hover">
              <Image filename="MakeYourOwnCotton-Thumbnail.png" />
              <div className="card__content">
                <h3>Make Your Own Cotton-Ball Launcher</h3>
                <h4>
                  What can a rubber band and a cotton ball teach you about
                  potential and kinetic energy?
                </h4>
                <p>
                  Join Michael Lewandowski to make your own cotton-ball launcher
                  and find out!
                </p>
              </div>
              <Link
                to="../make-your-own-cotton-ball-launcher"
                className="button bg-gradient-purple"
              >
                Learn More <Icon name="arrowright" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FeelingSound;
